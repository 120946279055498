.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
}

.picker {
  position: absolute;
  z-index: 2;
}
