@import './scss/variables.scss';
@import '~bootstrap/scss/bootstrap';
@import './scss/forms.scss';

:root {
  --dark: #{$dark};
  --link: #66b3ff;
  --body-bg: #{$dark};
  --body-color: #{$light};
  --form-control-bg: #{darken($dark, 10%)};
  --form-control-border: #{lighten($dark, 40%)};
  --form-control-disabled: #{lighten($dark, 10%)};
}

html {
  overflow-y: scroll;
}

html,
#root {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-bg);
  color: var(--body-color) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: var(--link);
}
